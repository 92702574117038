import React, { useState, useEffect } from 'react';
import { Box, Button, Grid, Typography, Tooltip } from '@mui/material';
import { AirlineSeatReclineNormal, Wc, Stairs, Kitchen, Edit, Block } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

const SeatSelection = React.memo(({ layoutAndar1, layoutAndar2, reservedSeats, onSelectSeat, handleEditReservation }) => {
    const [selectedSeats, setSelectedSeats] = useState([]);
  const [hoveredSeat, setHoveredSeat] = useState(null); // Estado para armazenar o assento sobre o qual o mouse está
  const theme = useTheme();

  useEffect(() => {
    onSelectSeat(selectedSeats);
  }, [selectedSeats, onSelectSeat]);

  const toggleSeatSelection = (seat) => {
    const reservedSeat = reservedSeats.find((reserved) => reserved.number === seat.number);

    if (reservedSeat) {
      // Chamando handleEditReservation se o assento estiver reservado
      handleEditReservation({ travelId: reservedSeat.travelId, ...reservedSeat }, reservedSeat.orderId);
    } else {
      setSelectedSeats((prevSelected) =>
        prevSelected.some((s) => s.number === seat.number)
          ? prevSelected.filter((s) => s.number !== seat.number)
          : [...prevSelected, seat]
      );
    }
  };

  const unflattenLayout = (flatLayout) => {
    const rows = Math.max(...flatLayout.map((cell) => cell.row)) + 1;
    const cols = Math.max(...flatLayout.map((cell) => cell.col)) + 1;
    const layout = Array.from({ length: rows }, () => Array(cols).fill({ type: 'empty', number: null }));

    flatLayout.forEach((cell) => {
      layout[cell.row][cell.col] = {
        type: cell.type || 'empty',
        number: cell.number ?? null,
      };
    });

    return layout;
  };

  const renderLayout = (layout) => {
    if (!Array.isArray(layout) || layout.length === 0) return null;

    return layout.map((row, rowIndex) => (
      <Grid container key={rowIndex} justifyContent="center" sx={{ marginBottom: '8px' }}>
        {row.map((cell, colIndex) => {
          const reservedSeat = reservedSeats.find((reserved) => reserved.number === cell.number);
          const isReserved = reservedSeat && reservedSeat.status !== 'Cancelada';
          const passengerInfo = reservedSeat?.passenger
            ? `${reservedSeat.passenger.nome} (${reservedSeat.passenger.cpf || reservedSeat.passenger.passaporte || reservedSeat.passenger.rg})`
            : '';

          let tooltipText = '';
          if (cell.type === 'seat' && cell.number) {
            tooltipText = isReserved ? passengerInfo : `Assento ${cell.number}`;
          } else if (cell.type === 'bathroom') {
            tooltipText = 'Banheiro';
          } else if (cell.type === 'stair') {
            tooltipText = 'Escada';
          } else if (cell.type === 'fridge') {
            tooltipText = 'Frigobar';
          }

          return (
            <React.Fragment key={colIndex}>
              {colIndex === 2 && <Grid item key={`aisle-${rowIndex}`} sx={{ width: '20px' }} />}
              <Grid item>
                <Tooltip title={tooltipText} arrow>
                  <span>
                    <Button
                      variant={selectedSeats.some((selected) => selected.number === cell.number) ? 'contained' : 'outlined'}
                      onClick={() => toggleSeatSelection(cell)}
                      onMouseEnter={() => setHoveredSeat(cell.number)} // Define o assento atual como "hovered" ao passar o mouse
                      onMouseLeave={() => setHoveredSeat(null)} // Limpa o estado "hovered" ao remover o mouse
                      disabled={cell.type !== 'seat'}
                      sx={{
                        width: '48px',
                        height: '48px',
                        margin: '4px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: isReserved
                          ? 'rgba(128, 128, 128, 0.5)'  // Cor de reservado com opacidade
                          : selectedSeats.some((selected) => selected.number === cell.number)
                            ? theme.palette.primary.main  // Cor de selecionado
                            : theme.palette.background.paper,  // Cor de disponível
                        color: isReserved
                          ? theme.palette.common.white
                          : selectedSeats.some((selected) => selected.number === cell.number)
                            ? theme.palette.mode === 'dark'
                              ? theme.palette.common.black
                              : theme.palette.common.white
                            : theme.palette.text.primary,
                        cursor: cell.type !== 'seat' ? 'default' : 'pointer',
                        visibility: cell.type === 'empty' ? 'hidden' : 'visible',
                      }}
                    >
                      {renderCellContent(cell, isReserved, hoveredSeat)}
                    </Button>
                  </span>
                </Tooltip>
              </Grid>
            </React.Fragment>
          );
        })}
      </Grid>
    ));
  };

  const renderCellContent = (cell, isReserved, hoveredSeat) => {
    // Se o assento estiver reservado e o mouse estiver sobre ele, mostra o ícone de edição
    if (isReserved && hoveredSeat === cell.number) {
      return <Edit />;
    }
    // Caso contrário, mostra o número do assento
    switch (cell.type) {
      case 'seat':
        return cell.number ? `${cell.number}` : <AirlineSeatReclineNormal />;
      case 'bathroom':
        return <Wc />;
      case 'stair':
        return <Stairs />;
      case 'fridge':
        return <Kitchen />;
      case 'empty':
      default:
        return <Block />;
    }
  };

  return (
    <Box>
      <Typography variant="h6" sx={{ marginBottom: '16px' }}>Selecione os Assentos</Typography>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '16px' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', marginRight: '16px' }}>
          <Button
            variant="outlined"
            sx={{
              width: '48px',
              height: '48px',
              margin: '4px',
              backgroundColor: 'rgba(128, 128, 128, 0.5)',  // Cor de assento reservado
              color: theme.palette.common.white
            }}
          />
          <Typography variant="body2" sx={{ marginLeft: '8px' }}>Reservado</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', marginRight: '16px' }}>
          <Button
            variant="outlined"
            sx={{
              width: '48px',
              height: '48px',
              margin: '4px',
              backgroundColor: theme.palette.background.paper,  // Cor de assento disponível
            }}
          />
          <Typography variant="body2" sx={{ marginLeft: '8px' }}>Disponível</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Button
            variant="outlined"
            sx={{
              width: '48px',
              height: '48px',
              margin: '4px',
              backgroundColor: theme.palette.mode === 'dark' 
                ? theme.palette.common.white 
                : theme.palette.common.black,  // Cor de fundo dependendo do modo do tema
              color: theme.palette.mode === 'dark' 
                ? theme.palette.common.black 
                : theme.palette.common.white, // Cor do texto dependendo do modo do tema
            }}
            
          />
          <Typography variant="body2" sx={{ marginLeft: '8px' }}>Selecionado</Typography>
        </Box>
      </Box>
      {layoutAndar1.length > 0 && (
        <Box sx={{ mb: 4 }}>
          <Typography variant="subtitle1" sx={{ marginBottom: '8px' }}>1º Andar</Typography>
          <Box sx={{ border: `1px solid ${theme.palette.divider}`, padding: '16px', borderRadius: '8px', backgroundColor: theme.palette.background.default }}>
            {renderLayout(unflattenLayout(layoutAndar1))}
          </Box>
        </Box>
      )}
      {layoutAndar2.length > 0 && (
        <Box>
          <Typography variant="subtitle1" sx={{ marginBottom: '8px' }}>2º Andar</Typography>
          <Box sx={{ border: `1px solid ${theme.palette.divider}`, padding: '16px', borderRadius: '8px', backgroundColor: theme.palette.background.default }}>
            {renderLayout(unflattenLayout(layoutAndar2))}
          </Box>
        </Box>
      )}
    </Box>
  );
});

export default SeatSelection;
