import React from 'react';
import { Box, Typography, Tooltip, Paper } from '@mui/material';
import { TrendingUp, TrendingDown, AccountBalanceWallet } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

const CostSummary = ({ totalCosts = 0, totalReceivings = 0 }) => {
  const balance = (totalReceivings || 0) - (totalCosts || 0); // Define balance de forma segura
  const theme = useTheme();

  return (
    <Paper
      elevation={3}
      sx={{
        p: 2,
        borderRadius: '8px',
        display: 'flex',
        justifyContent: 'space-around',
        gap: 2,
        flexWrap: 'wrap',
        backgroundColor: theme.palette.background.paper,
      }}
    >
      {/* Custos Totais */}
      <Tooltip title="Soma total dos custos da viagem" arrow>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <TrendingDown sx={{ fontSize: 24, color: theme.palette.error.main }} />
          <Box>
            <Typography variant="body2" color="textSecondary">
              Custos Totais
            </Typography>
            <Typography variant="body1" color="error">
              R$ {(totalCosts || 0).toFixed(2)}
            </Typography>
          </Box>
        </Box>
      </Tooltip>

      {/* Recebimentos Totais */}
      <Tooltip title="Soma total dos recebimentos da viagem" arrow>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <TrendingUp sx={{ fontSize: 24, color: theme.palette.success.main }} />
          <Box>
            <Typography variant="body2" color="textSecondary">
              Recebimentos Totais
            </Typography>
            <Typography variant="body1" color="success.main">
              R$ {(totalReceivings || 0).toFixed(2)}
            </Typography>
          </Box>
        </Box>
      </Tooltip>

      {/* Saldo Total */}
      <Tooltip title="Saldo total (Recebimentos - Custos)" arrow>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <AccountBalanceWallet sx={{ fontSize: 24, color: theme.palette.info.main }} />
          <Box>
            <Typography variant="body2" color="textSecondary">
              Saldo Total
            </Typography>
            <Typography variant="body1" color={balance >= 0 ? 'success.main' : 'error.main'}>
              R$ {balance.toFixed(2)}
            </Typography>
          </Box>
        </Box>
      </Tooltip>
    </Paper>
  );
};

export default CostSummary;
