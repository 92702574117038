import React from 'react';
import {
  Grid,
  Typography,
  TextField,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  IconButton,
  Alert,
  AlertTitle,
  Autocomplete,
  Tooltip,
  Box
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { formatCPF, formatRG } from '../../utils/utils';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import WarningIcon from '@mui/icons-material/Warning';
import CloseIcon from '@mui/icons-material/Close';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

const PassengerSelection = ({
  reservations,
  passengers,
  loadingPassengers,
  duplicateWarnings,
  underageWarnings,
  handleInputChange,
  handlePayerChange,
  handleOpenFormDialog,
  handleOpenSeatSelection,
  handleRemoveReservation,
  editingReservation,
}) => {
  const theme = useTheme();

  return (
    <Grid container spacing={2}>
      {reservations.map((reservation, index) => (
        <React.Fragment key={reservation.id || index}>
          <Grid item xs={12} container alignItems="center">
            {!editingReservation && reservations.length > 1 && (
              <Tooltip title="Remover assento do pedido">
                <IconButton
                  onClick={() => handleRemoveReservation(index)}
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            )}
            <Typography
              variant="subtitle1"
              sx={{ ml: 1, color: theme.palette.text.primary }}
            >
              Assento {reservation.numeroAssento}
            </Typography>
            <Tooltip title="Trocar assento">
              <IconButton
                onClick={() => handleOpenSeatSelection(index)}
                disabled={reservation.status === 'Cancelada'}
                sx={{
                  color: theme.palette.primary.main,
                  ml: 2, // Aumenta a margem à esquerda
                }}
              >
                <SwapHorizIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          {duplicateWarnings[index] && (
            <Grid item xs={12}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '8px 12px',
                  border: `1px dashed ${theme.palette.warning.main}`,
                  borderRadius: 1,
                  backgroundColor: theme.palette.warning.light,
                  opacity: 0.8,
                }}
              >
                <WarningIcon />
                <Typography variant="body2">
                  Aviso: Este passageiro já está alocado nesta viagem.
                </Typography>
              </Box>
            </Grid>
          )}
          {underageWarnings[index] && (
            <Grid item xs={12}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '8px 12px',
                  border: `1px dashed ${theme.palette.warning.main}`,
                  borderRadius: 1,
                  backgroundColor: theme.palette.warning.light,
                  opacity: 0.8,
                }}
              >
                <WarningIcon />
                <Typography variant="body2">
                  Aviso: O passageiro selecionado é menor de idade.
                </Typography>
              </Box>
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <Autocomplete
              options={passengers}
              getOptionLabel={(option) => {
                let label = `${option.nome}`;
                if (option.cpf) {
                  label += ` - CPF: ${formatCPF(option.cpf)}`;
                }
                if (option.estrangeiro) {
                  label += ` - Passaporte: ${option.passaporte}`;
                } else {
                  label += ` - RG: ${formatRG(option.rg)}`;
                }
                return label;
              }}
              loading={loadingPassengers}
              value={passengers.find((p) => p.id === reservation.passengerId) || null}
              onChange={(event, newValue) => handleInputChange(index, event, newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Buscar Passageiro"
                  fullWidth
                  required
                  disabled={reservation.status === 'Cancelada'}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: ( // Adiciona o botão no início
                      <Box sx={{ display: 'flex', alignItems: 'center', mr: 1 }}>
                        <Tooltip title="Adicionar Novo Passageiro">
                          <IconButton
                            onClick={handleOpenFormDialog}
                            sx={{ color: theme.palette.primary.main }}
                          >
                            <PersonAddIcon />
                          </IconButton>
                        </Tooltip>
                        {params.InputProps.startAdornment}
                      </Box>
                    ),
                    endAdornment: (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {loadingPassengers ? (
                          <CircularProgress color="inherit" size={20} sx={{ mr: 1 }} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </Box>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={reservation.pagador}
                  onChange={() => handlePayerChange(index)}
                  name="pagador"
                  sx={{ color: theme.palette.primary.main }}
                  disabled={reservation.status === 'Cancelada'}
                />
              }
              label="É o pagador do pedido"
              sx={{ color: theme.palette.text.primary }}
            />
          </Grid>
          {reservation.status === 'Cancelada' && (
            <Grid item xs={12}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '8px 12px',
                  border: `1px dashed ${theme.palette.warning.main}`,
                  borderRadius: 1,
                  backgroundColor: theme.palette.warning.light,
                  opacity: 0.8,
                }}
              >
                <WarningIcon />
                Este assento está cancelado e não pode ser modificado.
              </Box>
            </Grid>
          )}
        </React.Fragment>
      ))}
    </Grid>
  );
};

export default PassengerSelection;
