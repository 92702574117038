import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Button, Box, Typography, IconButton, Tooltip } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';

const PaymentRecordsHelp = () => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Tooltip title="Ajuda">
                <IconButton onClick={handleOpen} color="cancelar">
                    <HelpIcon />
                </IconButton>
            </Tooltip>

            <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
                <DialogTitle>Ajuda - Registros de Pagamento</DialogTitle>
                <DialogContent>
                    <Box sx={{ maxHeight: '500px', overflowY: 'auto' }}>
                        <Typography variant="body1" paragraph>
                            O componente <strong>Registros de Pagamento</strong> permite o gerenciamento detalhado dos registros de pagamentos para o pedido. Nele, você pode adicionar, editar e excluir informações sobre pagamentos individuais.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            <h2>1. Adicionar Registro de Pagamento</h2>
                            Para adicionar um novo registro, clique em <strong>Adicionar Pagamento</strong>. Um novo campo será exibido onde você pode inserir as informações do pagamento.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            <h2>2. Campos Obrigatórios para Registro</h2>
                            Cada registro de pagamento requer as seguintes informações obrigatórias:
                            - <strong>Data de Pagamento:</strong> Selecione a data em que o pagamento foi efetuado.
                            - <strong>Valor do Pagamento:</strong> O valor deve ser maior que zero e é exibido com o símbolo de moeda R$. Certifique-se de que o valor total de todos os registros não exceda o valor total do pedido.
                            - <strong>Método de Pagamento:</strong> Selecione o método utilizado (ex. Dinheiro, Pix, Cartão, etc).
                        </Typography>

                        <Typography variant="body1" paragraph>
                            <h2>3. Validação dos Campos</h2>
                            Cada campo do registro possui uma validação para garantir consistência:
                            - <strong>Valor do Pagamento:</strong> Valores negativos ou acima do total permitido serão rejeitados.
                            - <strong>Data de Pagamento e Método de Pagamento:</strong> São obrigatórios para cada registro e devem ser preenchidos corretamente.
                            Caso algum campo esteja incorreto, mensagens de erro serão exibidas ao lado do respectivo campo.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            <h2>4. Excluir Registro de Pagamento</h2>
                            Para remover um registro, clique no ícone de lixeira ao lado do registro desejado. Esta ação é irreversível.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            <h2>5. Mensagens de Erro</h2>
                            Qualquer erro nos campos de valor, data ou método de pagamento irá gerar uma mensagem de erro abaixo do campo correspondente. Verifique e corrija todos os erros antes de finalizar.
                        </Typography>
                    </Box>
                    <Button onClick={handleClose} variant="outlined" color="primary" sx={{ mt: 2, borderRadius: '50px' }}>
                        Fechar
                    </Button>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default React.memo(PaymentRecordsHelp);
