import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Box, Card, CardContent, Typography, Fade, IconButton, Snackbar, Alert, Button, Dialog, DialogContent, Divider, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PassengerSelection from './PassengerSelection';
import PaymentDetailsForm from './PaymentDetailsForm';
import PaymentRecords from './PaymentRecords';
import PassengerForm from '../passengers/PassengerForm';
import SeatChangeDialog from './SeatChangeDialog';
import PassengerAllocationHelp from '../helps/PassengerAllocationHelp';
import { getTravelById, getVehicleById } from '../../services/TravelService';
import { getAllPassengers } from '../../services/PassengerService';
import { addOrder, updateOrder, getOrderById, addReservation, updateReservation, getReservationsByTravelId, getAvailableSeats, getReservedSeats } from '../../services/OrderService';
import { validarCPF, unformatCPF } from '../../utils/utils';
import { getLayoutById } from '../../services/LayoutService';

const PassengerAllocation = ({ isCreating }) => {
  const { id: orderId, travelId } = useParams();
  const theme = useTheme();
  const location = useLocation();
  const { selectedSeats = [], editingReservation, editingOrderId, previousPage } = location.state || {};
  const navigate = useNavigate();

  const [loadingData, setLoadingData] = useState(true);

  const initialReservation = {
    nomePagador: '',
    cpfPagador: '',
    rgPagador: '',
    metodoPagamento: '',
    valorTotal: '',
    valorPago: '0',
    valorRestante: '0',
    informacoesAdicionais: ''
  };

  const sortReservationsBySeatNumber = (reservations) => {
    return reservations.sort((a, b) => a.numeroAssento - b.numeroAssento);
  };

  const [reservations, setReservations] = useState(() => {
    const initialReservations = editingReservation
      ? Array.isArray(editingReservation)
        ? [...editingReservation]
        : [{ ...editingReservation }]
      : (Array.isArray(selectedSeats) ? selectedSeats : []).map((seat) => ({
        numeroAssento: seat.number,
        passengerId: null,
        pagador: false
      }));
    return sortReservationsBySeatNumber(initialReservations);
  });

  const [detalhesPagamento, setPaymentDetails] = useState(
    editingReservation && Array.isArray(editingReservation) && editingReservation[0].detalhesPagamento
      ? editingReservation[0].detalhesPagamento
      : initialReservation
  );
  const [pagadorEstrangeiro, setPagadorEstrangeiro] = useState(false);
  const [errors, setErrors] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [passengers, setPassengers] = useState([]);
  const [loadingPassengers, setLoadingPassengers] = useState(false);
  const [existingReservations, setExistingReservations] = useState([]);
  const [openFormDialog, setOpenFormDialog] = useState(false);
  const [openSeatSelectionDialog, setOpenSeatSelectionDialog] = useState(false);
  const [selectedReservationIndex, setSelectedReservationIndex] = useState(null);
  const [layoutAndar1, setLayoutAndar1] = useState([]);
  const [layoutAndar2, setLayoutAndar2] = useState([]);
  const [editedPassenger, setEditedPassenger] = useState({});
  const [editing, setEditing] = useState(false);
  const [duplicateWarnings, setDuplicateWarnings] = useState(
    new Array(reservations.length).fill(false)
  );
  const [underageWarnings, setUnderageWarnings] = useState(
    new Array(reservations.length).fill(false)
  );
  const [paymentRecords, setPaymentRecords] = useState([]);
  const [availableSeatsAndar1, setAvailableSeatsAndar1] = useState([]);
  const [availableSeatsAndar2, setAvailableSeatsAndar2] = useState([]);
  const [reservedSeats, setReservedSeats] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchPassengers = useCallback(async () => {
    const fetchedPassengers = await getAllPassengers();
    fetchedPassengers.sort((a, b) => a.nome.localeCompare(b.nome));
    setPassengers(fetchedPassengers);
  }, []);

  const fetchExistingReservations = useCallback(async () => {
    try {
      const fetchedReservations = await getReservationsByTravelId(travelId);
      setExistingReservations(fetchedReservations);
    } catch (error) {
      console.error('Erro ao buscar reservas existentes:', error);
    }
  }, [travelId]);

  const fetchOrderDetails = useCallback(async () => {
    if (!orderId) return;
    try {
      const order = await getOrderById(travelId, orderId);
      setReservations(order.reservations.sort((a, b) => a.numeroAssento - b.numeroAssento));
      setPaymentDetails(order.detalhesPagamento);
      setPaymentRecords(order.detalhesPagamento.pagamentos || []);
    } catch (error) {
      console.error('Erro ao buscar detalhes do pedido:', error);
    }
  }, [orderId, travelId]);

  const fetchLayoutByVehicle = useCallback(async (vehicleId) => {
    try {
      const vehicleData = await getVehicleById(vehicleId);
      if (vehicleData?.layoutId) {
        const layoutData = await getLayoutById(vehicleData.layoutId);
        setLayoutAndar1(layoutData.firstFloor || []);
        setLayoutAndar2(layoutData.secondFloor || []);
      } else {
        throw new Error('Nenhum layoutId associado a este veículo.');
      }
    } catch (err) {
      console.error('Erro ao buscar layout do veículo:', err);
    }
  }, []);

  const fetchSeatData = useCallback(async () => {
    try {
      const travelData = await getTravelById(travelId);
      if (travelData?.veiculoId) await fetchLayoutByVehicle(travelData.veiculoId);

      const allReservedSeats = await getReservedSeats(travelId);
      setReservedSeats(allReservedSeats.filter((reservation) => reservation.status !== 'Cancelada'));
    } catch (error) {
      console.error('Erro ao buscar dados de assentos:', error);
    }
  }, [travelId, fetchLayoutByVehicle]);

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        setLoadingData(true);
        await Promise.all([fetchPassengers(), fetchSeatData(), fetchExistingReservations()]);
        if (!isCreating) await fetchOrderDetails();
      } finally {
        setLoadingData(false);
      }
    };
    fetchAllData();
  }, [fetchPassengers, fetchSeatData, fetchExistingReservations, fetchOrderDetails, isCreating]);

  const handleInputChange = (index, event, newValue) => {
    const passengerId = newValue ? newValue.id : null;
    const isDuplicate = checkDuplicatePassengerInTrip(passengerId, reservations[index]?.id);
    const isUnderagePassenger = newValue && newValue.menorDeIdade;

    setDuplicateWarnings((prevWarnings) => {
      const newWarnings = [...prevWarnings];
      newWarnings[index] = isDuplicate;
      return newWarnings;
    });

    setUnderageWarnings((prevWarnings) => {
      const newWarnings = [...prevWarnings];
      newWarnings[index] = isUnderagePassenger;
      return newWarnings;
    });

    setReservations((prevReservations) => {
      const newReservations = [...prevReservations];
      newReservations[index].passengerId = passengerId;
      return sortReservationsBySeatNumber(newReservations);
    });
    validateField(index, 'passengerId', passengerId);
  };

  const validateField = (index, name, value) => {
    let error = '';

    if (name === 'passengerId' && !value) {
      error = 'Passageiro é obrigatório.';
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [`${index}-${name}`]: error
    }));
  };

  const validatePaymentField = (name, value) => {
    let error = '';

    if (name === 'nomePagador' && (!value.trim() || value.length > 255)) {
      error = 'Nome do pagador é obrigatório e deve ter no máximo 255 caracteres.';
    } else if (name === 'cpfPagador' && (!value.trim() || !validarCPF(unformatCPF(value)))) {
      error = 'CPF do pagador é obrigatório e deve ser válido.';
    } else if (name === 'metodoPagamento' && !value.trim()) {
      error = 'Método de pagamento é obrigatório.';
    } else if (name === 'valorTotal' && parseFloat(value) < 0) {
      error = 'Valor total não pode ser negativo.';
    } else if (name === 'informacoesAdicionais' && value.length > 255) {
      error = 'Informações adicionais de pagamento devem ter no máximo 255 caracteres.';
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error
    }));
  };

  const isFormValid = () => {
    const passengerDetailsValid = reservations.every(
      (reservation) =>
        reservation.passengerId &&
        Object.keys(errors).every((key) => !errors[key])
    );

    const detalhesPagamentoValid = detalhesPagamento &&
      detalhesPagamento.nomePagador &&
      // Validação para CPF ou passaporte
      (
        (detalhesPagamento.cpfPagador && validarCPF(unformatCPF(detalhesPagamento.cpfPagador))) ||
        (detalhesPagamento.passaportePagador && detalhesPagamento.passaportePagador.trim() !== '')
      ) &&
      detalhesPagamento.metodoPagamento &&
      detalhesPagamento.valorTotal &&
      Object.keys(errors).every((key) => !errors[key]);

    const paymentRecordsValid = !errors.paymentRecord;

    return passengerDetailsValid && detalhesPagamentoValid && paymentRecordsValid;
  };

  const checkDuplicatePassengerInTrip = (newPassengerId, reservationId = null) => {
    return existingReservations.some(
      (reservation) =>
        reservation.passengerId === newPassengerId &&
        reservation.id !== reservationId &&
        reservation.status !== 'Cancelada'
    );
  };

  const handleAddPaymentRecord = () => {
    const today = new Date().toISOString().split('T')[0];
    setPaymentRecords([
      ...paymentRecords,
      { data: today, valor: '', metodoPagamento: detalhesPagamento.metodoPagamento }
    ]);
  };

  const handlePaymentRecordChange = (index, field, value) => {
    const updatedRecords = [...paymentRecords];
    updatedRecords[index][field] = value;

    if (field === 'valor') {
      const totalPaid = updatedRecords.reduce(
        (total, record) => total + parseFloat(record.valor.replace(',', '.') || 0),
        0
      );
      if (totalPaid > parseFloat(detalhesPagamento.valorTotal.replace(',', '.'))) {
        setSnackbarMessage(
          'O valor total pago não pode exceder o valor total da reserva.'
        );
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        return;
      }
      setPaymentDetails((prevDetails) => ({
        ...prevDetails,
        valorPago: totalPaid.toFixed(2),
        valorRestante: (
          parseFloat(prevDetails.valorTotal.replace(',', '.')) - totalPaid
        ).toFixed(2)
      }));
    }

    setPaymentRecords(updatedRecords);
  };

  const handleRemovePaymentRecord = (index) => {
    const updatedRecords = paymentRecords.filter((_, i) => i !== index);
    const valorTotalPago = updatedRecords
      .reduce((total, record) => total + parseFloat(record.valor.replace(',', '.') || 0), 0)
      .toFixed(2);
    setPaymentRecords(updatedRecords);

    setPaymentDetails((prevDetails) => ({
      ...prevDetails,
      valorPago: valorTotalPago,
      valorRestante: (
        parseFloat(prevDetails.valorTotal.replace(',', '.')) - valorTotalPago
      ).toFixed(2)
    }));

    updatedRecords.forEach((record, idx) => validatePaymentField('valor', record.valor));
  };

  const handlePaymentDetailChange = (name, value) => {
    setPaymentDetails((prevDetails) => {
      const updatedDetails = {
        ...prevDetails,
        [name]: value || '', // Define uma string vazia se o valor for undefined
      };

      if (name === 'valorTotal') {
        const numericValue = parseFloat(value.replace(',', '.')) || 0;
        updatedDetails.valorRestante = (numericValue - parseFloat(prevDetails.valorPago.replace(',', '.'))).toFixed(2);
      }

      return updatedDetails;
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!isFormValid()) {
      setSnackbarMessage('Preencha todos os campos obrigatórios corretamente.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    setLoading(true);

    try {
      let currentOrderId = orderId;

      const cleanDetalhesPagamento = {
        ...detalhesPagamento,
        passaportePagador: detalhesPagamento.passaportePagador || '',
        cpfPagador: detalhesPagamento.cpfPagador || '',
        rgPagador: detalhesPagamento.rgPagador || '',
        pagamentos: paymentRecords,
      };

      if (isCreating) {
        // Caso de criação
        const order = {
          travelId,
          detalhesPagamento: {
            ...cleanDetalhesPagamento,
            criadoEm: new Date().toISOString().split('T')[0],
          }
        };
        currentOrderId = await addOrder(order);
      } else {
        // Caso de edição
        await updateOrder(currentOrderId, {
          travelId,
          detalhesPagamento: {
            ...cleanDetalhesPagamento,
            editadoEm: new Date().toISOString().split('T')[0],
          }
        });
      }

      for (const reservation of reservations) {
        if (reservation.id) {
          await updateReservation(reservation.id, {
            ...reservation,
            travelId,
            orderId: currentOrderId,
            editadoEm: new Date().toISOString().split('T')[0]
          });
        } else {
          await addReservation(currentOrderId, {
            ...reservation,
            travelId,
            criadoEm: new Date().toISOString().split('T')[0]
          });
        }
      }

      setSnackbarMessage('Reservas salvas com sucesso!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      if (previousPage) {
        navigate(previousPage);
      } else {
        navigate(-1);
      }
    } catch (err) {
      console.error('Erro ao salvar reservas:', err);
      setSnackbarMessage('Erro ao salvar reservas. Por favor, tente novamente.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenFormDialog = () => {
    setOpenFormDialog(true);
    setEditedPassenger({});
    setEditing(false);
  };

  const handleCloseFormDialog = () => {
    setOpenFormDialog(false);
  };

  const handleOpenSeatSelection = (index) => {
    setSelectedReservationIndex(index);
    setOpenSeatSelectionDialog(true);
  };

  const handleCloseSeatSelection = () => {
    setOpenSeatSelectionDialog(false);
  };

  const currentlyAllocatedSeats = useMemo(
    () => reservations.map(reservation => reservation.numeroAssento),
    [reservations]
  );

  const handleSeatChange = (seatNumber) => {
    setReservations((prevReservations) => {
      const newReservations = [...prevReservations];
      if (selectedReservationIndex !== null) {
        newReservations[selectedReservationIndex].numeroAssento = seatNumber;
      }
      return sortReservationsBySeatNumber(newReservations);
    });
    handleCloseSeatSelection();
  };

  const handleRemoveReservation = (index) => {
    if (reservations.length > 1) {
      setReservations((prevReservations) => {
        const newReservations = prevReservations.filter((_, idx) => idx !== index);
        return newReservations;
      });
    }
  };

  const handlePayerChange = (index) => {
    setReservations((prevReservations) => {
      // Atualiza a lista de reservas, definindo apenas um pagador
      const newReservations = prevReservations.map((reservation, idx) => ({
        ...reservation,
        pagador: idx === index ? !reservation.pagador : false, // Define pagador apenas no índice selecionado
      }));

      // Identifica o passageiro vinculado à reserva que foi marcada como pagador
      const selectedReservation = newReservations.find((res) => res.pagador);
      if (selectedReservation) {
        const payerPassenger = passengers.find((passenger) => passenger.id === selectedReservation.passengerId);

        if (payerPassenger) {
          // Define os dados do pagador com base em se o passageiro é estrangeiro ou não
          setPaymentDetails((prevDetails) => ({
            ...prevDetails,
            nomePagador: payerPassenger.nome,
            passaportePagador: payerPassenger.estrangeiro ? payerPassenger.passaporte || '' : '',
            cpfPagador: payerPassenger.estrangeiro ? '' : payerPassenger.cpf || '',
            rgPagador: payerPassenger.estrangeiro ? '' : payerPassenger.rg || '',
          }));

          // Atualiza o estado de pagador estrangeiro
          setPagadorEstrangeiro(!!payerPassenger.estrangeiro);
        }
      } else {
        // Limpa os dados do pagador se nenhum pagador for selecionado
        setPaymentDetails({
          ...initialReservation,
          metodoPagamento: detalhesPagamento.metodoPagamento, // Mantém o método de pagamento atual
        });
        setPagadorEstrangeiro(false); // Restaura a opção de pagador brasileiro
      }

      return newReservations;
    });
  };

  return (
    <Box>
      <Box sx={{ p: theme.spacing(3) }}>
        {loadingData ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-start',
              height: 'auto',
              marginTop: theme.spacing(3),
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Fade in={true}>
              <IconButton onClick={() => navigate(previousPage || -1)} sx={{ mb: theme.spacing(2) }}>
                <ArrowBackIcon />
              </IconButton>
            </Fade>
            <Card
              variant="outlined"
              sx={{
                mb: theme.spacing(3),
                borderRadius: 2, // Sem bordas arredondadas para o card
                boxShadow: theme.shadows[1],
              }}
            >
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Alocar Passageiros
                  <Box component="span" sx={{ ml: 1 }}> {/* Adiciona margem à esquerda */}
                    <PassengerAllocationHelp />
                  </Box>
                </Typography>

                <form onSubmit={handleSubmit} noValidate>
                  <Box sx={{ mb: theme.spacing(3) }}>
                    <PassengerSelection
                      reservations={reservations}
                      passengers={passengers}
                      duplicateWarnings={duplicateWarnings}
                      underageWarnings={underageWarnings}
                      loadingPassengers={loadingPassengers}
                      handleInputChange={handleInputChange}
                      handlePayerChange={handlePayerChange}
                      handleOpenFormDialog={handleOpenFormDialog}
                      handleOpenSeatSelection={handleOpenSeatSelection}
                      handleRemoveReservation={handleRemoveReservation}
                      editingReservation={editingReservation}
                    />
                  </Box>
                  <Divider sx={{ my: theme.spacing(3) }} />
                  <Box sx={{ mb: theme.spacing(3) }}>
                    <PaymentDetailsForm
                      detalhesPagamento={detalhesPagamento}
                      handlePaymentDetailChange={handlePaymentDetailChange}
                      errors={errors}
                      validatePaymentField={validatePaymentField}
                      setPagadorEstrangeiro={setPagadorEstrangeiro}
                    />
                  </Box>
                  <Divider sx={{ my: theme.spacing(3) }} />
                  <Box sx={{ mb: theme.spacing(3) }}>
                    <PaymentRecords
                      paymentRecords={paymentRecords}
                      handlePaymentRecordChange={handlePaymentRecordChange}
                      handleRemovePaymentRecord={handleRemovePaymentRecord}
                      handleAddPaymentRecord={handleAddPaymentRecord}
                      detalhesPagamento={detalhesPagamento}
                      setErrors={setErrors}
                    />
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: theme.spacing(2) }}>
                    <Button
                      variant="outlined"
                      sx={{
                        borderRadius: 2, // Sem bordas arredondadas para o botão
                        color: theme.palette.action,
                      }}
                      onClick={() => navigate(previousPage || -1)}
                    >
                      {editingReservation ? 'Descartar Alterações' : 'Voltar'}
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      // disabled={!isFormValid() || loading}
                      startIcon={loading && <CircularProgress size={20} />}
                      sx={{
                        borderRadius: 2, // Sem bordas arredondadas para o botão
                      }}
                    >
                      {editingReservation
                        ? reservations.length > 1 ? 'Atualizar Reservas' : 'Atualizar Reserva'
                        : reservations.length > 1 ? 'Salvar Reservas' : 'Salvar Reserva'}
                    </Button>
                  </Box>
                </form>
                {errors.form && (
                  <Typography color="error" sx={{ mt: theme.spacing(2) }}>
                    {errors.form}
                  </Typography>
                )}
              </CardContent>
            </Card>
            <Dialog open={openFormDialog} onClose={handleCloseFormDialog}>
              <DialogContent>
                <PassengerForm
                  editedPassenger={editedPassenger}
                  setEditedPassenger={setEditedPassenger}
                  errors={errors}
                  setErrors={setErrors}
                  handleCloseFormDialog={handleCloseFormDialog}
                  fetchPassageiros={fetchPassengers}
                  editing={editing}
                  passageiros={passengers}
                  setOpenSnackbar={setSnackbarOpen}
                  setSnackbarMessage={setSnackbarMessage}
                />
              </DialogContent>
            </Dialog>
            <SeatChangeDialog
              open={openSeatSelectionDialog}
              onClose={handleCloseSeatSelection}
              currentSeat={selectedReservationIndex !== null ? reservations[selectedReservationIndex].numeroAssento : null}
              layoutAndar1={layoutAndar1}
              layoutAndar2={layoutAndar2}
              reservedSeats={reservedSeats}
              allocatedSeats={currentlyAllocatedSeats}
              onSeatChange={handleSeatChange}
            />
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
              <Alert
                onClose={() => setSnackbarOpen(false)}
                severity={snackbarSeverity}
                sx={{ width: '100%', backgroundColor: theme.palette.background.default }}
              >
                {snackbarMessage}
              </Alert>
            </Snackbar>
          </>
        )}
      </Box>
    </Box>
  );
};

export default PassengerAllocation;
