import { db, auth } from '../firebaseConfig';
import { collection, doc, getDocs, query, where, getDoc } from 'firebase/firestore';
import { getTravelById } from './TravelService';

// Função para determinar o status de uma reserva baseado no status do pedido
const getReservationStatus = (reservation, order) => {
  if (reservation.status === 'Cancelada' || order.status === 'Cancelada') {
    return 'Cancelada';
  }
  return order.detalhesPagamento?.valorRestante > 0 ? 'Pagamento pendente' : 'Pago';
};

// Função para obter todas as reservas
export const getAllReservations = async () => {
  const userId = auth.currentUser.uid; // Obtém o ID do usuário atual
  const userDocRef = doc(db, 'usuarios', userId); // Referência ao documento do usuário
  const travelCollectionRef = collection(userDocRef, 'viagens'); // Referência à coleção de viagens do usuário
  const travelSnapshot = await getDocs(travelCollectionRef); // Busca todos os documentos de viagens

  const allReservations = []; // Array para armazenar todas as reservas
  const passengerMap = new Map(); // Mapa para armazenar dados dos passageiros

  // Busca todos os passageiros uma vez
  const passengerCollectionRef = collection(db, 'usuarios', userId, 'passageiros');
  const passengersSnapshot = await getDocs(passengerCollectionRef);
  passengersSnapshot.docs.forEach(doc => {
    const passengerData = doc.data();
    passengerMap.set(doc.id, { id: doc.id, ...passengerData });
  });

  // Itera sobre todas as viagens
  for (const travelDoc of travelSnapshot.docs) {
    const travelData = travelDoc.data();

    // Filtra viagens que não estão ativas
    if (!travelData.estaAtivo) continue;

    const travelId = travelDoc.id;
    const ordersCollectionRef = collection(travelDoc.ref, 'pedidos'); // Referência à coleção de pedidos
    const ordersSnapshot = await getDocs(ordersCollectionRef); // Busca todos os pedidos da viagem

    // Itera sobre todos os pedidos
    for (const orderDoc of ordersSnapshot.docs) {
      const orderData = orderDoc.data();
      const detalhesPagamento = orderData.detalhesPagamento || {}; // Detalhes do pagamento do pedido
      const reservationCollectionRef = collection(orderDoc.ref, 'reservas'); // Referência à coleção de reservas
      const reservationSnapshot = await getDocs(reservationCollectionRef); // Busca todas as reservas do pedido

      // Itera sobre todas as reservas
      reservationSnapshot.docs.forEach((doc) => {
        const reservationData = doc.data();
        const passengerId = reservationData.passengerId;
        const passenger = passengerMap.get(passengerId) || null; // Obtém dados do passageiro

        // Define o status da reserva baseado no pedido
        const status = getReservationStatus(reservationData, orderData);

        // Adiciona a reserva ao array de todas as reservas
        allReservations.push({
          id: doc.id,
          ...reservationData,
          orderId: orderDoc.id,
          detalhesPagamento,
          passenger,
          travelId,
          status
        });
      });
    }
  }

  return allReservations;
};

export const getAllOrders = async () => {
  const userId = auth.currentUser.uid; // Obtém o ID do usuário atual
  const userDocRef = doc(db, 'usuarios', userId); // Referência ao documento do usuário
  const travelCollectionRef = collection(userDocRef, 'viagens'); // Referência à coleção de viagens do usuário
  const travelSnapshot = await getDocs(travelCollectionRef); // Busca todos os documentos de viagens

  const allOrders = []; // Array para armazenar todos os pedidos
  const passengerMap = new Map(); // Mapa para armazenar dados dos passageiros

  // Busca todos os passageiros uma vez
  const passengerCollectionRef = collection(db, 'usuarios', userId, 'passageiros');
  const passengersSnapshot = await getDocs(passengerCollectionRef);
  passengersSnapshot.docs.forEach(doc => {
    const passengerData = doc.data();
    passengerMap.set(doc.id, { id: doc.id, ...passengerData });
  });

  // Itera sobre todas as viagens
  for (const travelDoc of travelSnapshot.docs) {
    const travelData = travelDoc.data();

    // Filtra viagens que não estão ativas
    if (!travelData.estaAtivo) continue;

    const travelId = travelDoc.id;
    const ordersCollectionRef = collection(travelDoc.ref, 'pedidos'); // Referência à coleção de pedidos
    const ordersSnapshot = await getDocs(ordersCollectionRef); // Busca todos os pedidos da viagem

    // Itera sobre todos os pedidos
    for (const orderDoc of ordersSnapshot.docs) {
      const orderData = orderDoc.data();
      const detalhesPagamento = orderData.detalhesPagamento || {}; // Detalhes do pagamento do pedido
      const reservationCollectionRef = collection(orderDoc.ref, 'reservas'); // Referência à coleção de reservas
      const reservationSnapshot = await getDocs(reservationCollectionRef); // Busca todas as reservas do pedido

      const reservations = reservationSnapshot.docs.map(doc => {
        const reservationData = doc.data();
        const passengerId = reservationData.passengerId;
        const passenger = passengerMap.get(passengerId) || null; // Obtém dados do passageiro

        return {
          id: doc.id,
          ...reservationData,
          passenger,
          status: reservationData.status || 'Indefinido', // Status da reserva
        };
      });

      allOrders.push({
        id: orderDoc.id,
        ...orderData,
        detalhesPagamento,
        reservations,
        travelId,
        status: orderData.status || 'Indefinido' // Status do pedido
      });
    }
  }

  return allOrders;
};

const fetchReservationsWithSeats = async (orderDocRef) => {
  const reservationCollectionRef = collection(orderDocRef, 'reservas');
  const reservationSnapshot = await getDocs(reservationCollectionRef);
  
  // Mapear reservas com número de assento e status
  return reservationSnapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data(),
    numeroAssento: doc.data().numeroAssento || null, // Inclui o número do assento
    status: doc.data().status || 'Indefinido'
  }));
};

// Função atualizada para buscar pedidos pendentes com reservas e assentos
export const getPendingOrders = async () => {
  const userId = auth.currentUser.uid;
  const today = new Date();
  const currentMonth = today.getMonth() + 1;
  const currentYear = today.getFullYear();

  const userDocRef = doc(db, 'usuarios', userId);
  const travelCollectionRef = collection(userDocRef, 'viagens');
  const travelSnapshot = await getDocs(travelCollectionRef);

  const pendingOrders = [];

  for (const travelDoc of travelSnapshot.docs) {
    const travelData = travelDoc.data();
    const travelDate = new Date(travelData.dataIda);

    if (travelData.estaAtivo && travelDate.getMonth() + 1 === currentMonth && travelDate.getFullYear() === currentYear) {
      const ordersCollectionRef = collection(travelDoc.ref, 'pedidos');
      const ordersSnapshot = await getDocs(ordersCollectionRef);

      for (const orderDoc of ordersSnapshot.docs) {
        const orderData = orderDoc.data();

        if (orderData.status !== 'Cancelada' && parseFloat(orderData.detalhesPagamento?.valorRestante || '0') > 0) {
          // Busca as reservas com os detalhes de assentos para o pedido
          const reservations = await fetchReservationsWithSeats(orderDoc.ref);
          pendingOrders.push({
            id: orderDoc.id,
            ...orderData,
            travelId: travelDoc.id,
            detalhesPagamento: orderData.detalhesPagamento || {},
            reservations // Inclui as reservas com os assentos
          });
        }
      }
    }
  }

  return pendingOrders;
};

// Função atualizada para buscar pedidos pagos com reservas e assentos
export const getPaidOrders = async () => {
  const userId = auth.currentUser.uid;
  const today = new Date();
  const currentMonth = today.getMonth() + 1;
  const currentYear = today.getFullYear();

  const userDocRef = doc(db, 'usuarios', userId);
  const travelCollectionRef = collection(userDocRef, 'viagens');
  const travelSnapshot = await getDocs(travelCollectionRef);

  const paidOrders = [];

  for (const travelDoc of travelSnapshot.docs) {
    const travelData = travelDoc.data();
    const travelDate = new Date(travelData.dataIda);

    if (travelData.estaAtivo && travelDate.getMonth() + 1 === currentMonth && travelDate.getFullYear() === currentYear) {
      const ordersCollectionRef = collection(travelDoc.ref, 'pedidos');
      const ordersSnapshot = await getDocs(ordersCollectionRef);

      for (const orderDoc of ordersSnapshot.docs) {
        const orderData = orderDoc.data();

        if (orderData.status !== 'Cancelada' && parseFloat(orderData.detalhesPagamento?.valorRestante || '0') === 0) {
          // Busca as reservas com os detalhes de assentos para o pedido
          const reservations = await fetchReservationsWithSeats(orderDoc.ref);
          paidOrders.push({
            id: orderDoc.id,
            ...orderData,
            travelId: travelDoc.id,
            detalhesPagamento: orderData.detalhesPagamento || {},
            reservations // Inclui as reservas com os assentos
          });
        }
      }
    }
  }

  return paidOrders;
};

export const calculateMonthlyTotals = async (month, year) => {
  const pendingOrders = await getPendingOrders();
  const paidOrders = await getPaidOrders();
  const allOrders = [...pendingOrders, ...paidOrders];

  let totalReceivable = 0;
  let totalReceived = 0;
  const travelsData = {};

  // Calcular os totais com base nos pedidos de um determinado mês e ano
  for (const order of allOrders) {
    if (order.status !== 'Cancelada') {
      // Verificar e buscar detalhes de viagem se não estiver em cache
      const travel = travelsData[order.travelId] || await getTravelById(order.travelId);
      travelsData[order.travelId] = travel;

      const travelDate = new Date(travel.dataIda);
      if (travelDate.getMonth() + 1 === month && travelDate.getFullYear() === year) {
        totalReceived += parseFloat(order.detalhesPagamento?.valorPago || 0);
        totalReceivable += parseFloat(order.detalhesPagamento?.valorRestante || 0);
      }
    }
  }

  return { totalReceivable, totalReceived };
};

// Função para obter todos os passageiros
export const getAllPassengers = async () => {
  const userId = auth.currentUser.uid; // Obtém o ID do usuário atual
  const userDocRef = doc(db, 'usuarios', userId); // Referência ao documento do usuário
  const passengerCollectionRef = collection(userDocRef, 'passageiros'); // Referência à coleção de passageiros
  const q = query(passengerCollectionRef, where("estaAtivo", "==", true)); // Consulta para buscar apenas passageiros ativos
  const snapshot = await getDocs(q); // Executa a consulta
  return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })); // Mapeia os dados dos passageiros
};