import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Button, Box, Typography, IconButton, Tooltip } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';

const PassengerAllocationHelp = () => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Tooltip title="Ajuda">
                <IconButton onClick={handleOpen} color="cancelar">
                    <HelpIcon />
                </IconButton>
            </Tooltip>

            <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
                <DialogTitle>Ajuda - Alocação de Passageiros</DialogTitle>
                <DialogContent>
                    <Box sx={{ maxHeight: '500px', overflowY: 'auto' }}>
                        <Typography variant="body1" paragraph>
                            Este módulo permite gerenciar a alocação de passageiros em uma viagem, incluindo a seleção de assentos, detalhes do pagamento e registros de pagamentos.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            <h2>1. Seleção de Passageiros</h2>
                            Através da seção de seleção de passageiros, você pode alocar assentos e associar cada passageiro ao seu respectivo assento. Em caso de duplicação ou de menores de idade, o sistema emitirá alertas específicos.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            <h2>2. Detalhes do Pagamento</h2>
                            Insira informações completas sobre o pagamento, incluindo o nome do pagador e a nacionalidade. Dependendo da escolha de brasileiro ou estrangeiro, campos específicos de CPF/RG ou passaporte serão exibidos. Certifique-se de que os dados estão preenchidos corretamente para evitar erros de validação.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            <h2>3. Registro de Pagamentos</h2>
                            Adicione registros de pagamentos feitos para esta reserva. É possível inserir valores, datas e métodos de pagamento. Caso o valor total pago exceda o valor da reserva, o sistema emitirá um alerta.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            <h2>4. Salvar ou Voltar</h2>
                            Após preencher todas as informações e validar os dados, clique em "Salvar" para registrar as informações. Se preferir sair sem salvar, use a opção "Voltar" para retornar à página anterior sem aplicar mudanças.
                        </Typography>
                    </Box>
                    <Button onClick={handleClose} variant="outlined" sx={{ mt: 2, borderRadius: '50px' }}>
                        Fechar
                    </Button>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default React.memo(PassengerAllocationHelp);
