import { formatCPF, formatDate, formatDateForFilename } from './utils';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import logo from '../assets/logo4.png';

export const exportOrdersToPDF = (travel, orders, passengers) => {
  const doc = new jsPDF('landscape', 'pt');

  const truncateText = (text, maxLength) => {
    if (!text) return 'Não informado';
    return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
  };

  const columns = [
    { title: "Pedido ID", dataKey: "orderId" },
    { title: "Assentos", dataKey: "numerosAssentos" },
    { title: "Nome do Pagador", dataKey: "nomePagador" },
    { title: "CPF do Pagador", dataKey: "cpfPagador" },
    { title: "RG do Pagador", dataKey: "rgPagador" },
    { title: "Valor Total", dataKey: "valorTotal" },
    { title: "Método de Pagamento", dataKey: "metodoPagamento" },
    { title: "Status", dataKey: "status" },
    { title: "Informações Adicionais", dataKey: "informacoesAdicionais" }
  ];

  const filteredOrders = orders.filter(order => order.status !== 'Cancelada');

  const rows = filteredOrders.map(order => {
    const assentos = order.reservations.map(reservation => reservation.numeroAssento).join(', ');
    const informacoesAdicionais = order.reservations
      .map(reservation => reservation.detalhesPagamento?.informacoesAdicionais)
      .filter(info => info)
      .join('; ');

    const nomePagador = order.detalhesPagamento?.nomePagador || 'Não informado';
    const cpfPagador = order.detalhesPagamento?.passaportePagador
      ? `Passaporte: ${order.detalhesPagamento.passaportePagador}`
      : order.detalhesPagamento?.cpfPagador
        ? formatCPF(order.detalhesPagamento.cpfPagador)
        : 'Não informado';

    const rgPagador = order.detalhesPagamento?.passaportePagador
      ? 'Não aplicável'
      : order.detalhesPagamento?.rgPagador || 'Não informado';

    const valorTotal = order.detalhesPagamento?.valorTotal ? `R$ ${Number(order.detalhesPagamento.valorTotal).toFixed(2)}` : 'Não informado';
    const metodoPagamento = order.detalhesPagamento?.metodoPagamento || 'Não informado';

    return {
      orderId: order.id,
      numerosAssentos: assentos,
      nomePagador: nomePagador,
      cpfPagador: cpfPagador,
      rgPagador: rgPagador,
      valorTotal: valorTotal,
      metodoPagamento: metodoPagamento,
      status: order.status,
      informacoesAdicionais: truncateText(informacoesAdicionais, 50)
    };
  });

  const pageHeight = doc.internal.pageSize.height;
  const pageWidth = doc.internal.pageSize.width;

  const img = new Image();
  img.src = logo;

  img.onload = () => {
    // Adiciona a logo no lado direito
    doc.addImage(img, 'PNG', pageWidth - 130, 20, 100, 60);  // Logo posicionada no topo direito

    // Título e informações da viagem à esquerda da logo
    doc.setFontSize(18);
    doc.setFont('helvetica', 'bold');
    doc.text('Detalhes da Viagem', 40, 40);

    doc.setFontSize(12);
    doc.setFont('helvetica', 'normal');
    doc.text(`Identificador: ${travel?.identificador || 'Não informado'}`, 40, 60);
    doc.text(`Origem: ${travel?.origem || 'Não informado'}`, 40, 75);
    doc.text(`Destino: ${travel?.destino || 'Não informado'}`, 40, 90);
    doc.text(`Data de Ida: ${formatDate(travel?.dataIda) || 'Não informado'} às ${travel?.horarioIda || 'Não informado'}`, 40, 105);

    if (travel?.somenteIda) {
      doc.setTextColor(0, 102, 204);  // Azul para "Somente Ida"
      doc.text(`Somente Ida`, 40, 120);
      doc.setTextColor(0, 0, 0);  // Reset para preto
    } else {
      doc.text(`Data de Retorno: ${formatDate(travel?.dataRetorno) || 'Não informado'} às ${travel?.horarioRetorno || 'Não informado'}`, 40, 120);
    }

    if (travel?.veiculo) {
      doc.text(`Veículo: ${travel.veiculo.identificadorVeiculo || 'Não informado'} - ${travel.veiculo.placa || 'Não informado'} (${travel.veiculo.empresa || 'Não informado'})`, 40, 135);
    }
    if (travel?.informacoesAdicionais) {
      doc.text(`Informações Adicionais: ${travel.informacoesAdicionais}`, 40, 150);
    }

    doc.autoTable({
      head: [columns.map(col => col.title)],
      body: rows.map(row => columns.map(col => row[col.dataKey])),
      startY: 180,
      theme: 'striped',
      margin: { top: 10, bottom: 30, left: 20, right: 20 },
      styles: { fontSize: 10, halign: 'center' },
      headStyles: { fillColor: [40, 60, 100], textColor: [255, 255, 255] },
      alternateRowStyles: { fillColor: [240, 240, 240] },
      columnStyles: {
        orderId: { cellWidth: 50 },
      },
      didDrawPage: function (data) {
        doc.setFontSize(10);
        doc.setTextColor(40);

        let str = 'Página ' + doc.internal.getNumberOfPages();
        if (typeof doc.putTotalPages === 'function') {
          str = str + ' de ' + doc.internal.getNumberOfPages();
        }
        doc.setFontSize(10);
        doc.text(str, data.settings.margin.left, pageHeight - 10);
      },
    });

    const identifier = travel?.identificador || 'sem-identificador';
    const origem = travel?.origem || 'sem-origem';
    const destino = travel?.destino || 'sem-destino';
    const dataIda = formatDateForFilename(travel?.dataIda || 'sem-data-ida');
    const dataRetorno = travel?.somenteIda ? 'somente-ida' : formatDateForFilename(travel?.dataRetorno || 'sem-data-retorno');
    const filename = `pedidos_${identifier}_de_${origem}_na_data_${dataIda}_a_${destino}_com_volta_${dataRetorno}.pdf`;

    doc.save(filename);
  };
};
