import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Button, Box, Typography, IconButton, Tooltip } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';

const PaymentDetailsFormHelp = () => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Tooltip title="Ajuda">
                <IconButton onClick={handleOpen} color="cancelar">
                    <HelpIcon />
                </IconButton>
            </Tooltip>

            <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
                <DialogTitle>Ajuda - Detalhes do Pagamento</DialogTitle>
                <DialogContent>
                    <Box sx={{ maxHeight: '500px', overflowY: 'auto' }}>
                        <Typography variant="body1" paragraph>
                            Este formulário permite gerenciar as informações detalhadas do pagamento do pedido, incluindo a escolha de nacionalidade do pagador, dados de documentos, valor total e método de pagamento.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            <h2>1. Seleção de Nacionalidade do Pagador</h2>
                            Use as opções de nacionalidade para selecionar se o pagador é "Brasileiro" ou "Estrangeiro". Esta seleção altera os campos obrigatórios do formulário:
                            - <strong>Brasileiro:</strong> CPF e RG são obrigatórios.
                            - <strong>Estrangeiro:</strong> O campo de Passaporte é habilitado, enquanto CPF e RG são desativados.
                            Para garantir que a nacionalidade do pagador reflete corretamente o passageiro designado, atualizações automáticas são aplicadas quando um pagador é selecionado.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            <h2>2. Preenchimento de Valor Total</h2>
                            Insira o valor total do pedido utilizando o formato monetário brasileiro. O valor é automaticamente formatado ao perder o foco no campo. Apenas valores numéricos e uma vírgula são permitidos para evitar erros de formatação.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            <h2>3. Método de Pagamento</h2>
                            Escolha o método de pagamento para o pedido entre as opções fornecidas, como "Dinheiro", "Pix", "Cartão", entre outros. Certifique-se de selecionar o método correto, pois é obrigatório.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            <h2>4. Informações Adicionais do Pedido</h2>
                            Este campo é destinado a observações adicionais relacionadas ao pagamento e pode ter no máximo 255 caracteres. Use-o para especificar detalhes relevantes ao pagamento que possam auxiliar em sua gestão.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            <h2>5. Salvamento e Validação</h2>
                            Antes de salvar, todos os campos obrigatórios devem estar preenchidos e válidos. Caso algum campo esteja incorreto, mensagens de erro serão exibidas ao lado dos campos problemáticos.
                        </Typography>
                    </Box>
                    <Button onClick={handleClose} variant="outlined" color="primary" sx={{ mt: 2, borderRadius: '50px' }}>
                        Fechar
                    </Button>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default React.memo(PaymentDetailsFormHelp);
