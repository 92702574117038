import { db, auth } from '../firebaseConfig';
import { collection, doc, addDoc, getDocs, updateDoc, where, query } from 'firebase/firestore';

// Função para obter todos os custos associados a uma viagem
export const getCostsByTravelId = async (travelId) => {
  const userId = auth.currentUser.uid;
  const travelDocRef = doc(db, 'usuarios', userId, 'viagens', travelId);
  const costsCollectionRef = collection(travelDocRef, 'custos');

  // Cria uma consulta que filtra os custos onde `estaAtivo` é `true`
  const activeCostsQuery = query(costsCollectionRef, where("estaAtivo", "==", true));
  const snapshot = await getDocs(activeCostsQuery);

  const costs = snapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));

  return costs;
};

// Função para adicionar um novo custo a uma viagem
export const addCost = async (travelId, cost) => {
  const userId = auth.currentUser.uid;
  const travelDocRef = doc(db, 'usuarios', userId, 'viagens', travelId);
  const costsCollectionRef = collection(travelDocRef, 'custos');

  const timestamp = new Date().toISOString();
  const costWithMetadata = {
    ...cost,
    estaAtivo: true,             // Define o custo como ativo
    dataCriacao: timestamp,       // Define a data de criação
    dataAtualizacao: timestamp,   // Define a data de atualização inicial
    dataExclusao: null,           // Nenhuma data de exclusão ainda
  };

  const costDoc = await addDoc(costsCollectionRef, costWithMetadata);
  return { id: costDoc.id, ...costWithMetadata };
};

// Função para atualizar um custo existente
export const updateCost = async (travelId, costId, updatedCost) => {
  const userId = auth.currentUser.uid;
  const costDocRef = doc(db, 'usuarios', userId, 'viagens', travelId, 'custos', costId);

  const costWithUpdatedTimestamp = {
    ...updatedCost,
    dataAtualizacao: new Date().toISOString(), // Atualiza o timestamp de atualização
  };

  return await updateDoc(costDocRef, costWithUpdatedTimestamp);
};

// Função para "deletar" um custo de uma viagem (definindo `estaAtivo: false` e `dataExclusao`)
export const deleteCost = async (travelId, costId) => {
  const userId = auth.currentUser.uid;
  const costDocRef = doc(db, 'usuarios', userId, 'viagens', travelId, 'custos', costId);

  const costWithDeletionMetadata = {
    estaAtivo: false,                  // Marca como inativo
    dataExclusao: new Date().toISOString(), // Define a data de exclusão
  };

  return await updateDoc(costDocRef, costWithDeletionMetadata);
};
