import React, { useState, useEffect } from 'react';
import {
  Typography, Box, IconButton, List, ListItem, Divider, Chip, Menu, MenuItem, Button, CircularProgress
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ListIcon from '@mui/icons-material/List';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { getReservedSeats } from '../../services/OrderService';
import { formatDate } from '../../utils/utils';

const statusStyles = (theme) => ({
  'Cancelada': { color: theme.palette.error.light, text: 'Cancelada' },
  'Em andamento': { color: theme.palette.success.main, text: 'Em andamento' },
  'Próxima': { color: theme.palette.info.main, text: 'Próxima' },
  'Encerrada': { color: theme.palette.grey[600], text: 'Encerrada' },
  'Criada': { color: theme.palette.info.light, text: 'Criada' },
  'Indefinido': { color: theme.palette.grey[500], text: 'Indefinido' }
});

function TravelList({ travels, startEditing, handleDelete, handleCancel }) {
  const navigate = useNavigate();
  const theme = useTheme();
  const [reservedSeatsData, setReservedSeatsData] = useState({});
  const [loadingSeats, setLoadingSeats] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentTravel, setCurrentTravel] = useState(null);

  useEffect(() => {
    const fetchReservedSeats = async () => {
      setLoadingSeats(true);
      const data = {};
      for (const travel of travels) {
        const reservedSeats = await getReservedSeats(travel.id);
        const activeSeats = reservedSeats.filter(reservation => reservation.status !== 'Cancelada').length;
        data[travel.id] = activeSeats;
      }
      setReservedSeatsData(data);
      setLoadingSeats(false);
    };
    fetchReservedSeats();
  }, [travels]);

  const handleViewReservations = (travelId, e) => {
    e.stopPropagation();
    navigate(`/viagens/${travelId}/reservas`);
  };

  const handleViewCosts = (travelId, e) => {
    e.stopPropagation();
    navigate(`/viagens/${travelId}/custos`);
  };

  const handleOpenMenu = (e, travel) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
    setCurrentTravel(travel);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setCurrentTravel(null);
  };

  const handleEdit = (e) => {
    e.stopPropagation();
    startEditing(currentTravel);
    handleCloseMenu();
  };

  const handleCancelTravel = (e) => {
    e.stopPropagation();
    handleCancel(currentTravel);
    handleCloseMenu();
  };

  const handleDeleteTravel = (e) => {
    e.stopPropagation();
    handleDelete(currentTravel);
    handleCloseMenu();
  };

  const appliedStatusStyles = statusStyles(theme);

  return (
    <Box sx={{ width: '100%' }}>
      <List>
        {travels.length > 0 ? (
          travels.map(travel => {
            const status = appliedStatusStyles[travel.status] || appliedStatusStyles['Indefinido'];
            const totalSeats = (parseInt(travel.assentosAndar1, 10) || 0) + (parseInt(travel.assentosAndar2, 10) || 0);
            const occupiedSeats = reservedSeatsData[travel.id] || 0;

            return (
              <React.Fragment key={travel.id}>
                <ListItem
                  button
                  onClick={() => navigate(`/viagens/${travel.id}`)}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    mb: 1,
                    p: 1.5,
                    border: `1px solid ${theme.palette.divider}`,
                    borderRadius: 2,
                    backgroundColor: theme.palette.background.paper,
                  }}
                >
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mb: 1 }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography variant="subtitle1" color="text.primary">{travel.origem} ➔ {travel.destino}</Typography>
                      <Typography variant="body2" color="text.secondary">Data de Ida: {formatDate(travel.dataIda)}</Typography>
                      {travel.somenteIda ? (
                        <Typography variant="body2" color="text.secondary">Viagem somente de ida</Typography>
                      ) : (
                        <Typography variant="body2" color="text.secondary">Data de Retorno: {formatDate(travel.dataRetorno)}</Typography>
                      )}
                      <Typography variant="body2" color="text.secondary" sx={{ display: 'flex', alignItems: 'center', mt: 0.5 }}>
                        Assentos Ocupados:
                        {loadingSeats ? (
                          <CircularProgress size={12} sx={{ marginLeft: 1 }} />
                        ) : (
                          <Box component="span" sx={{ ml: 1 }}>{`${occupiedSeats}/${totalSeats}`}</Box>
                        )}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Veículo: {travel.veiculo ? `${travel.veiculo.identificadorVeiculo} - ${travel.veiculo.placa}` : 'Nenhum veículo associado'}
                      </Typography>
                    </Box>

                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                      <Chip label={status.text} sx={{ backgroundColor: status.color, mb: 0.5 }} color="primary" />
                      <IconButton onClick={(e) => handleOpenMenu(e, travel)} edge="end" size="small">
                        <MoreVertIcon color="action" />
                      </IconButton>
                      <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
                        <Button
                          size="small"
                          variant="outlined"
                          startIcon={<ListIcon />}
                          onClick={(e) => handleViewReservations(travel.id, e)}
                          sx={{ fontWeight: 'medium', padding: '4px 8px', borderRadius: '50px' }}
                        >
                          Reservas
                        </Button>
                        <Button
                          size="small"
                          variant="outlined"
                          startIcon={<AttachMoneyIcon />}
                          onClick={(e) => handleViewCosts(travel.id, e)}
                          sx={{ fontWeight: 'medium', padding: '4px 8px', borderRadius: '50px' }}
                        >
                          Financeiro
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </ListItem>
                <Divider />
              </React.Fragment>
            );
          })
        ) : (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <Typography variant="body1" >
              Nenhuma viagem encontrada.
            </Typography>
          </Box>
        )}
      </List>

      {/* Menu de opções para editar, cancelar ou excluir */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        PaperProps={{
          style: {
            maxHeight: 48 * 4.5,
            width: '20ch',
            backgroundColor: theme.palette.background.paper,
          },
        }}
      >
        {currentTravel && currentTravel.status !== 'Cancelada' && (
          <MenuItem onClick={handleEdit}>
            <EditIcon fontSize="small" /> Editar
          </MenuItem>
        )}
        {currentTravel && currentTravel.status !== 'Encerrada' && currentTravel.status !== 'Cancelada' && (
          <MenuItem onClick={handleCancelTravel}>
            <CancelIcon fontSize="small" /> Cancelar
          </MenuItem>
        )}
        {currentTravel && currentTravel.status !== 'Encerrada' && currentTravel.status !== 'Cancelada' && (
          <MenuItem onClick={handleDeleteTravel}>
            <DeleteIcon fontSize="small" /> Excluir
          </MenuItem>
        )}
      </Menu>
    </Box>
  );
}

export default TravelList;
