import { db, auth } from '../firebaseConfig';
import { collection, doc, addDoc, getDocs, getDoc, updateDoc, query, where } from 'firebase/firestore';

// Função para adicionar um novo layout ao Firestore
export const addLayout = async (layout) => {
  try {
    const userId = auth.currentUser.uid;
    const userDocRef = doc(db, 'usuarios', userId);
    const layoutCollectionRef = collection(userDocRef, 'layouts');
    const dataAdicionado = new Date().toISOString();
    const docRef = await addDoc(layoutCollectionRef, { ...layout, estaAtivo: true, dataExclusao: null, dataAdicionado });
    return docRef.id; // Retorna o ID do documento
  } catch (error) {
    console.error('Erro ao adicionar layout:', error);
    throw error;
  }
};


// Função para verificar se um usuário já possui layouts
export const userHasLayouts = async () => {
  const userId = auth.currentUser.uid;
  const userDocRef = doc(db, 'usuarios', userId);
  const layoutCollectionRef = collection(userDocRef, 'layouts');
  const q = query(layoutCollectionRef, where('estaAtivo', '==', true));
  const snapshot = await getDocs(q);
  return !snapshot.empty;
};

// Função para criar um layout padrão
export const createDefaultLayoutForNewUser = async () => {
  const userId = auth.currentUser.uid;
  const hasLayouts = await userHasLayouts();

  if (!hasLayouts) {
    const { firstFloorLayout, secondFloorLayout } = initializeDefaultLayout(); // Inicializa os layouts de ambos os andares
    const layoutData = {
      name: 'Layout Padrão',
      firstFloor: firstFloorLayout,
      secondFloor: secondFloorLayout,
      assentosAndar1: firstFloorLayout.filter(cell => cell.type === 'seat').length,
      assentosAndar2: secondFloorLayout.filter(cell => cell.type === 'seat').length,
      assentosTotais: firstFloorLayout.filter(cell => cell.type === 'seat').length + secondFloorLayout.filter(cell => cell.type === 'seat').length,
      doisAndares: true,
      userId: userId,
    };

    // Adiciona o layout e captura o ID retornado
    const layoutId = await addLayout(layoutData);
    if (layoutId) {
      return layoutId; // Retorna o ID do layout criado
    } else {
      return null; // Retorna null em caso de erro
    }
  } else {
    return null;
  }
};

// Função para inicializar o layout padrão com 2 andares
const initializeDefaultLayout = () => {
  const rows = 10;
  const cols = 4;

  // Inicializa o layout do primeiro andar
  const firstFloorLayout = [];
  let seatNumber = 1;
  for (let i = 0; i < rows; i++) {
    const row = [];
    for (let j = 0; j < cols; j++) {
      // Adiciona assentos e outros tipos de assentos em posições específicas
      if (i === 2 && j === 1) {
        row.push({ type: 'bathroom', number: null });
      } else if (i === 5 && j === 2) {
        row.push({ type: 'fridge', number: null });
      } else {
        row.push({ type: 'seat', number: seatNumber++ });
      }
    }
    firstFloorLayout.push(row);
  }

  // Inicializa o layout do segundo andar
  const secondFloorLayout = [];
  for (let i = 0; i < rows; i++) {
    const row = [];
    for (let j = 0; j < cols; j++) {
      if (i === 3 && j === 0) {
        row.push({ type: 'bathroom', number: null });
      } else if (i === 7 && j === 3) {
        row.push({ type: 'fridge', number: null });
      } else {
        row.push({ type: 'seat', number: seatNumber++ });
      }
    }
    secondFloorLayout.push(row);
  }

  return {
    firstFloorLayout: flattenLayout(firstFloorLayout),
    secondFloorLayout: flattenLayout(secondFloorLayout),
  };
};

// Função para "achatar" o layout em uma estrutura linear
const flattenLayout = (layout) => {
  const flatLayout = [];
  layout.forEach((row, rowIndex) => {
    row.forEach((cell, colIndex) => {
      flatLayout.push({
        row: rowIndex,
        col: colIndex,
        type: cell.type,
        number: cell.number,
      });
    });
  });
  return flatLayout;
};

// Função para atualizar um layout específico no Firestore
export const updateLayout = async (layoutId, layoutData) => {
  const userId = auth.currentUser.uid;
  const layoutDocRef = doc(db, 'usuarios', userId, 'layouts', layoutId);

  // Remover campos undefined do layoutData
  const cleanedLayoutData = removeUndefinedFields(layoutData);

  return await updateDoc(layoutDocRef, cleanedLayoutData);
};


const removeUndefinedFields = (obj) => {
  const cleanObj = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] !== undefined) {
      cleanObj[key] = obj[key];
    }
  });
  return cleanObj;
};

// Função para marcar um layout como inativo no Firestore (exclusão lógica)
export const deleteLayout = async (layoutId) => {
  const userId = auth.currentUser.uid;
  const layoutDocRef = doc(db, 'usuarios', userId, 'layouts', layoutId);
  const dataExclusao = new Date().toISOString();
  return await updateDoc(layoutDocRef, { estaAtivo: false, dataExclusao });
};

// Função para obter todos os layouts ativos do Firestore
export const getAllLayouts = async () => {
  const userId = auth.currentUser.uid;
  const userDocRef = doc(db, 'usuarios', userId);
  const layoutCollectionRef = collection(userDocRef, 'layouts');
  const q = query(layoutCollectionRef, where('estaAtivo', '==', true));
  const snapshot = await getDocs(q);
  return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
};

// Função para obter um layout específico pelo ID
export const getLayoutById = async (layoutId) => {
  const userId = auth.currentUser.uid;
  const layoutDocRef = doc(db, 'usuarios', userId, 'layouts', layoutId);
  const layoutDoc = await getDoc(layoutDocRef);

  if (layoutDoc.exists()) {
    return { id: layoutDoc.id, ...layoutDoc.data() };
  } else {
    throw new Error('Layout não encontrado');
  }
};
